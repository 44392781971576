@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');


#body {
    background-color: #FFFFFF;
    height: 500px;
}

#content {
	padding-top: 60px;
	font-family: 'Lato', sans-serif;
	padding-left: 30%;
	padding-right: 30%;
}

#footer {
	padding-top: 60px;
    height: 40%;
}

.navbar {
    background-color: #FFFFFF;
}

#logo {
    font-size: 25px;
    font-weight: 700;
    color: rgb(21,22,23) !important;
}

.page-section {
    display: block;
	margin-top: 50px;
    /* background-color: #FFFFFF; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* border-radius: 20px; */
}

.stat-list {
	margin-top: 20px;
}

.stat-card {
	background-color: transparent;
	margin-top: 0px;
	margin-bottom: 0px;
    border-bottom: 2px solid #EEEEEEEE;
    box-shadow: 0px 0px;
}

.stat-value {
    font-weight: 700;
    font-size: 17.5px;
}

.section-header {
    font-size: 20px;
    font-weight: 700;
    color: rgb(125, 125, 125) !important;
}

.stat-card-content {
	/* background-color: white; */
	/* cursor: pointer; */
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
	width: 100%;
	transition: 0.5s;
	font-size: 18;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}

.name {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
    font-size: 17.5px;
}

.icon {
    margin-right: 7.5px;
}

.chart {
	display: none;
	background-color: white;
	overflow: hidden;
}

.advertisement {
    position: absolute;
    top: 70px;
    right: 20px;
    width: 200px;
    height: 100px;
    overflow: hidden;
}



@media(max-width: 767.98px){
    #content {
        padding-left: 15%;
        padding-right: 15%;
    }
}